
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$creator-portal-ui-primary: mat.define-palette(mat.$indigo-palette);
$creator-portal-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$creator-portal-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$creator-portal-ui-theme: mat.define-light-theme((
  color: (
    primary: $creator-portal-ui-primary,
    accent: $creator-portal-ui-accent,
    warn: $creator-portal-ui-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($creator-portal-ui-theme);

/* You can add global styles to this file, and also import other style files */
// body{
//     margin: auto;
// }

@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');


html, body { height: 100%; }
body { margin: 0; font-family: syne !important;}
*{ margin: 0; font-family: syne !important;}

::ng-deep{
  .mat-typography{
    margin: 0;
    font-family: inherit;
  }
  .mat-typography p{
    margin: 0;
  }
  .mat-typography{
    font-family: syne !important;
  }
}
